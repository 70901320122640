.AddReviewSuccess {
    max-width: 600px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to bottom, #767ae8, #ac49d8);
    text-align: center;
    color: white;
    padding-top: 70px;
    padding: 70px 10px 0;
}

.AddReviewSuccess h1, .AddReviewSuccess h2 {
    font-weight: normal;
}

.AddReviewSuccess a {
    display: block;
    color: white;
    font-size: large;
    width: 300px;
    padding: 15px 0;
    border: 1px solid white;
    border-radius: 5px;
    margin: 50px auto 0;
    text-decoration: none;
}