.Payment {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 10px;
    min-height: 100vh;
    justify-content: space-between;
}

.Payment button {
    align-self: center;
    display: block;
    color: white;
    font-size: large;
    width: 300px;
    padding: 15px 0;
    border: none;
    outline: none;
    border-radius: 5px;
    margin: 20px auto 0px;
    cursor: pointer;
}

.Payment .apply-payment {
    background-image: linear-gradient(to right, #3cf7e1, #04bbd1);
}

.Payment .apply-payment:disabled {
    background-color: rgb(6, 181, 201, 0.4);
}

.Payment .deny-payment {
    background-image: linear-gradient(to right, #767ae8, #ac49d8);
}

.Payment .deny-payment:disabled {
    background-color: rgb(139, 103, 226, 0.4);
}

.Payment .apply-payment:disabled, .Payment .deny-payment:disabled {
    background-image: none;
    cursor: default;
}

.Payment .descr {
    text-align: justify;
}

.Payment .descr li{
    margin: 5px 0;
}