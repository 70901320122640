.TextField {
    width: 100%;
    min-width: 300px;
    min-height: 5em;
    margin-bottom: 7px;
}

.TextField fieldset {
    box-sizing: border-box;
    border: 1px solid silver;
    border-radius: 5px;
    margin: 0;
}

.TextField.textarea fieldset {
    min-height: 8em;
}

.TextField legend {
    font-size: small;
}

.TextField fieldset > div {
    display: flex;
    margin: 5px 0;
}

.TextField input, .TextField textarea {
    border: none;
    outline: none;
    flex: 1;
    margin: 0 5px;
    font-size: medium;
}

.TextField input::placeholder {
    font-size: small;
}

.TextField textarea {
    resize: none;
    height: 6em;
    overflow: hidden;
    font-family: inherit;
}

.TextField .leadingIcon, .TextField .trailingIcon {
    cursor: pointer;
}

.TextField .alert {
    font-size: small;
    color: tomato;
}