.MsgBox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #1010109d;
    z-index: 50;
}

.MsgBox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    background-color: white;
    width: 98%;
    max-width: 600px;
    padding: 10px;
    border-radius: 5px;
}

.MsgBox .txt {
    text-align: center;
    padding: 5px;
    margin-bottom: 20px;
}

.MsgBox .wrap-buttons {
    display: flex;
    justify-content: space-evenly;
}

.MsgBox button {
    color: white;
    font-size: large;
    width: 100px;
    padding: 10px 5px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}

.MsgBox button.OK {
    background-image: linear-gradient(to right, #3cf7e1, #04bbd1);
}

.MsgBox button.cancel {
    background-image: linear-gradient(to right, #e46b81, #d33a88);
}
