.PhoneView {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px dotted gainsboro;
    padding: 5px 0;
}

.PhoneView .info {
    flex: 2;
    margin: 10px;
}

.PhoneView .edit, .PhoneView .del {
    flex: 1;
    width: 1em;
    height: 1em;
    background-position: center;
    margin: 10px;
    cursor: pointer;
}

.PhoneView .edit {
    background: url(edit.svg) no-repeat;
}

.PhoneView .del {
    background: url(trash.svg) no-repeat;
}