.Tariff {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-bottom: 20px;
}

.Tariff h3 {
    text-align: center;
}

.Tariff h3 span {
    font-size: small;
    font-weight: normal;
    font-style: italic;
    color: #494949;
    margin-left: 5px;
}

.Tariff .feature {
    display: flex;
    align-items: center;
    border-bottom: 1px dotted gainsboro;
    padding: 10px;
}

.Tariff .feature .feature-name {
    flex: 2;
}

.Tariff .feature .feature-value {
    flex: 1;
    text-align: right;
    font-weight: bold;
}

.Tariff button {
    align-self: center;
    color: white;
    font-size: large;
    width: 300px;
    padding: 15px 0;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    background-image: linear-gradient(to right, #767ae8, #ac49d8);
}

.Tariff button:disabled {
    background-image: none;
    background-color: rgb(139, 103, 226, 0.4);
    cursor: default;
}

.Tariff .Prolongate {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}

.Tariff .Prolongate > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

.Tariff .Prolongate > div > div {
    flex: 2
}

.Tariff .Prolongate > div > span {
    flex: 1;
    text-align: right;
}

.Tariff .Prolongate > div > div > * {
    margin-right: 10px;
}

.Tariff .Prolongate div.selected {
    font-weight: bold;
}

.Tariff .Prolongate > div > div > *:first-child {
    color: #0dc4d3;
}

.Tariff .android-features {
    background-color: azure;
}

.Tariff .android-features > div:first-child {
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
}

.Tariff .expire-date {
    text-align: right;
    font-style: italic;
    color: #494949;
    margin-top: 10px;
}