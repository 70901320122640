.ContainerHead {
    position: relative;
}

.ContainerHead a {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.ContainerHead h1 {
    text-align: center;
    font-weight: normal;
}