.VisitType {
    margin: 10px 0;
    color: #505050;
    transition: all .2s ease-in-out;
}

.VisitType > *:first-child {
    cursor: pointer;
}

.VisitType.valid {
    color: black;
    font-weight: bold;
}

.VisitType.valid > *:first-child {
    color: #0dc4d3;
}

.VisitType span {
    margin-left: 10px;
}

.VisitType > div {
    font-weight: normal;
    margin-left: 3em;
}