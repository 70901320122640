.NavBar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-top: 1px dotted gainsboro;
    background-color: white;
    z-index: 100;
}

.NavBar nav {
    display: flex;
    justify-content: center;
}

.NavBar nav a {
    width: 30px;
    height: 30px;
    margin: 10px 20px;
    background-position: center;
}

.NavBar nav a.search {
    background: url(search_not_active.svg) no-repeat;
}

.NavBar nav a.search.active {
    background: url(search_active.svg) no-repeat;
}

.NavBar nav a.settings {
    background: url(settings_not_active.svg) no-repeat;
}

.NavBar nav a.settings.active {
    background: url(settings_active.svg) no-repeat;
}

.NavBar nav a.add-review {
    background: url(add_not_active.svg) no-repeat;
}

.NavBar nav a.add-review.active {
    background: url(add_review.svg) no-repeat;
}