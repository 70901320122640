.PhoneList {
    padding: 0 5px;
    max-width: 600px;
    margin: 0 auto;
}

.PhoneList .add-phone {
    display: inline-block;
    margin: 10px auto;
    background: url(plus_phone.svg) no-repeat;
    background-size: 1em;
    background-position: left;
    background-position-x: 7px;
    padding: 10px 10px 10px 30px;
    text-align: center;
    border: 1px solid gainsboro;
    border-radius: 5px;
    color: #303030;
    cursor: pointer;
}

.PhoneList .save {
    display: inline-block;
    color: white;
    font-size: large;
    width: 300px;
    padding: 15px 0;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    background-image: linear-gradient(to right, #767ae8, #ac49d8);
}

.PhoneList .save:disabled {
    background-image: none;
    background-color: rgb(139, 103, 226, 0.4);
}
