.SetCategory {
    margin: 10px auto;
    transition: all .2s ease-in-out;
}

.SetCategory .category {
    display: flex;
    align-items: center;
    color: #505050;
    margin-bottom: 10px;
}

.SetCategory .category.active {
    color: black;
}

.SetCategory .category .icon {
    margin-right: 10px;
    cursor: pointer;
}

.SetCategory .category.active .icon {
    color: #0dc4d3;
}