.SetRating {
    min-width: 300px;
    margin-bottom: 20px;
}

.SetRating .value-str {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.SetRating .stars {
    display: flex;
    justify-content: center;
}

.SetRating .stars * {
    margin: 0 5px;
}

.SetRating.alert .value-str span {
    color: tomato;
}