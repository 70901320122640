.ScoreView {
    box-sizing: border-box;
    color: white;
    background-image: linear-gradient(to right, #e46b81, #d33a88);
}

.ScoreView .rating {
    display: flex;
    justify-content: center;
    padding: 5px 15px;
}

.ScoreView .score {
    display: flex;
    align-items: center;
    border: 1px solid white;
    padding: 3px 10px;
    font-size: x-large;
}

.ScoreView .score img {
    margin-right: 3px;
}

.ScoreView .prescription {
    color: #d33a88;
    background-color: white;
    font-size: large;
    font-weight: 600;
    display: flex;
    align-items: center;
    border: 1px solid white;
    padding: 3px 15px;
}

.ScoreView.bearable {
    background-image: linear-gradient(to right, #7369d2, #7b58b5);
}

.ScoreView.bearable .prescription {
    color: #7369d2;
}

.ScoreView.recommended {
    background-image: linear-gradient(to right, #3cf7e1, #04bbd1);
}

.ScoreView.recommended .prescription {
    color: #04bbd1;
}

.ScoreView .rating .count{
    text-align: center;
    margin: 0px 20px;
}

.ScoreView .rating .count span{
    font-size: x-large;
}

.ScoreView .rating:first-child {
    padding-top: 15px;
}

.ScoreView .rating:last-child {
    padding-bottom: 15px;
}