.SelectItem {
    position: absolute;
    list-style: none;
    background-color: white;
    border: 1px dotted gainsboro;
    border-radius: 5px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    z-index: 100;
    box-shadow: 2px 2px 10px gainsboro;
}

.SelectItem li {
    padding: 5px 10px;
}

.SelectItem li:not(:last-child) {
    border-bottom: 1px dotted gainsboro;
}

.SelectItem li:hover {
    background-color: hsla(186, 94%, 41%, 0.2);
}