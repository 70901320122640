.InitAppScreen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    /* background-color: white; */
    background: url(logo.svg) no-repeat;
    background-size: 60px 60px;
    background-position: center;
}