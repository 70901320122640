.FBSearchForm {
    max-width: 600px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 70px;
    padding: 0 10px;
}

.FBSearchForm h1 {
    font-weight: normal;
}

.FBSearchForm > p {
    text-align: center;
}

.FBSearchForm button {
    display: block;
    color: white;
    font-size: large;
    width: 300px;
    padding: 15px 0;
    border: none;
    outline: none;
    border-radius: 5px;
    margin: 20px auto 0;
    cursor: pointer;
    background-image: linear-gradient(to right, #767ae8, #ac49d8);
}

.FBSearchForm button:disabled {
    background-image: none;
    background-color: rgb(139, 103, 226, 0.4);
}