.PaymentLogo {
    width: 100%;
    text-align: center;
}

.PaymentLogo > a {
    display: inline-block;
    font-size: small;
    margin: 0 10px 10px;
}

.PaymentLogo > div{
    width: 100%;
    height: 40px;
    background: url(./pay-systems-logo.png) no-repeat;
    background-size: contain;
    background-position: center;
}