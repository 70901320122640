.CognitoAuth {
    max-width: 600px;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.CognitoAuth .main-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.logo {
    height: 25vh;
    margin-top: 20px;
}

.CognitoAuth fieldset {
    border-radius: 5px;
    text-align: left;
    width: 300px;
    margin: 20px auto 0;
    box-sizing: inherit;
}

.CognitoAuth input {
    border: none;
    outline: none;
    font-size: large;
}

.CognitoAuth input::placeholder {
    font-size: small;
}

.getSMS, .enter {
    color: white;
    font-size: large;
    width: 300px;
    padding: 15px 0;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}

.getSMS {
    background-image: linear-gradient(to right, #3cf7e1, #04bbd1);
}

.getSMS:disabled {
    background-image: none;
    background-color: rgb(6, 181, 201, 0.4);
}

.enter {
    background-image: linear-gradient(to right, #767ae8, #ac49d8);
}

.enter:disabled {
    background-image: none;
    background-color: rgb(139, 103, 226, 0.4);
}

.CognitoAuth .foot-block {
    background-image: linear-gradient(to top, #3cf7e1, #04bbd1);
    padding: 5px;
    border-radius: 5px 5px 0 0;
}

.CognitoAuth .foot-block .company-info {
    font-size: small;
    color: white;
    display: flex;
}

.CognitoAuth .foot-block .company-info > div {
    flex: 1;
    margin: 0 5px;
}

.CognitoAuth .foot-block a {
    color: white;
}

.CognitoAuth .i-agree {
    font-size: small;
    padding: 40px 5px;
    text-align: center;
}