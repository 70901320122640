.PhoneCard {
    position: relative;
    background-color: white;
    border-radius: 5px;
    border-bottom: 1px dotted gainsboro;
    padding: 10px 5px;
    z-index: 100;
}

.PhoneCard-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(220, 220, 220, 0.315);
    z-index: 50;
}

.PhoneCard .wrap-input {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.PhoneCard .wrap-input > label {
    flex: 1;
}

.PhoneCard .wrap-input > input {
    flex: 2;
    outline: none;
    border: none;
    border-bottom: 1px solid gainsboro;
    font-size: large;
}

.PhoneCard .wrap-input > input::placeholder {
    font-size: x-small;
}

.PhoneCard .wrap-checkbox {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.PhoneCard .wrap-checkbox label {
    font-size: small;
}

.PhoneCard .wrap-buttons {
    display: flex;
    justify-content: space-evenly;
}

.PhoneCard button {
    color: white;
    font-size: large;
    width: 100px;
    padding: 10px 5px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}

.PhoneCard button.OK {
    background-image: linear-gradient(to right, #3cf7e1, #04bbd1);
}

.PhoneCard button.OK:disabled {
    background-image: none;
    background-color: rgb(6, 181, 201, 0.4);
}

.PhoneCard button.cancel {
    background-image: linear-gradient(to right, #e46b81, #d33a88);
}

.PhoneCard .del-warning {
    text-align: center;
    margin-bottom: 20px;
}