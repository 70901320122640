.Feedback {
    margin-top: 10px;
    border-bottom: 1px solid rgb(206, 203, 203);
}

.Feedback .author-name {
    display: flex;
    justify-content: space-between;
    font-style: italic;
    font-size: small;
    color: #3c3c3c;
    margin-bottom: 15px;
}

.Feedback .opt {
    display: flex;
    text-align: center;
    color: #3c3c3c;
}

.Feedback .opt > div {
    padding: 0 5px;
}

.Feedback .rating {
    flex: 1;
    border-right: lightgray solid 1px;
}

.Feedback .rating-value {
    font-weight: bold;
}

.Feedback .city {
    flex: 1;
    border-right: lightgray solid 1px;
}

.Feedback .categories {
    flex: 1;
}

.Feedback .category-name {
    font-size: small;
}

.Feedback .comment {
    text-align: justify;
    padding: 10px 0px;
}

.Feedback .phone-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 10px;
}

.Feedback .phone-list span {
    margin-right: 10px;
}
