.AddReview {
    box-sizing: border-box;
    max-width: 600px;
    margin: 0 auto 70px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AddReview h1 {
    font-weight: normal;
}

.AddReview > p {
    text-align: center;
}

.AddReview button {
    display: block;
    color: white;
    font-size: large;
    width: 300px;
    padding: 15px 0;
    border: none;
    outline: none;
    border-radius: 5px;
    margin: 20px auto 0;
    cursor: pointer;
    background-image: linear-gradient(to right, #767ae8, #ac49d8);
}
