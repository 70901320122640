.Msg {
    text-align: center;
    width: 100%;
    max-width: 600px;
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
    transition: transform 0.5s ease-in-out;
    cursor: pointer;
    box-sizing: border-box;
    z-index: 20;
}

.Msg.success {
    background-image: linear-gradient(to right, #3cf7e1, #04bbd1);
}

.Msg.info {
    background-image: linear-gradient(to right, #7369d2, #7b58b5);
}

.Msg.error {
    background-image: linear-gradient(to right, #e46b81, #d33a88);
}

.Msg.hide {
    transform: translate(-50%, -100%);
}