.AboutPayment {
    max-width: 600px;
    margin: auto;
    padding: 0 10px;
}

.AboutPayment .description {
    text-align: justify;
}

.AboutPayment .description li {
    margin: 5px 0;
}