.MyProfile {
    box-sizing: border-box;
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 70px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.MyProfile .main-block {
    width: 100%;
}

.MyProfile h1 {
    text-align: center;
    font-weight: normal;
}

.MyProfile p {
    text-align: left;
    margin: 5px 10px;
}

.MyProfile ul {
    list-style: none;
}

.MyProfile .f-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    min-height: 3em;
    border-bottom: 1px dotted gainsboro;
}

.MyProfile .phones-container {
    position: relative;
    overflow: hidden;
}

.MyProfile .phones-container .phones {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    cursor: default;
    padding: 10px 10px 10px 0;
}

.MyProfile img {
    margin-left: 10px;
    padding: 10px;
}

.MyProfile .footer {
    font-size: small;
}

.MyProfile .footer a {
    display: inline-block;
    padding: 10px 20px;
}