.FBSearch {
    max-width: 600px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto 70px;
    padding: 0 10px;
}

.FBSearch .no-result {
    text-align: center;
}